import NProgress from 'nprogress'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          key: '1'
        },
        component: () => import(/* webpackChunkName: "indexView" */ '../views/Index/indexView.vue')
      },
      {
        path: '/dataReport',
        name: 'dataReport',
        meta: {
          title: '数据报表',
          key: '2'
        },
        component: () => import(/* webpackChunkName: "dataReport" */ '../views/DataReport/dataReport.vue')
      },
      {
        path: '/dataReports',
        name: 'dataReports',
        meta: {
          title: '数据报表',
          key: '3'
        },
        component: () => import(/* webpackChunkName: "dataReports" */ '../views/DataReports/dataReports.vue')
      },
      {
        path: '/comparison',
        name: 'comparison',
        meta: {
          title: '数据报表',
          key: '4'
        },
        component: () => import(/* webpackChunkName: "comparison" */ '../views/Comparison/comparison.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      key: '2'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/loginView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  if (to.path !== '/login') {
    if (token) {
      // NProgress.start()
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    if (token) {
      next({ path: '/' })
    } else {
      // NProgress.start()
      next()
    }
  }
})
router.afterEach((to, from, next) => {
  // NProgress.done()
  if (to.meta.title) {
    document.title = 'SCATOMS';
  }
})
export default router
