<template>
  <div style="min-width: 1440px;background-color: rgb(240, 242, 245);">
    <div class="title">
      <div class="left">
        <img style="width: 100px;" :src="img" alt="">
        <div class="bgimg"></div>
      </div>
      
			<div class="reight">
        <img style="margin-right: 40px;width: 24px;height: 24px;" src="../assets/images/download.png" alt="">
        <a-popover placement="bottomRight">
          <template #content>
          <div class="dl" style="text-align: center;cursor: pointer;" @click="out">退出登录</div>
            </template>
          <div>
            <span style="font-size: 16px;color: #1D2129;">Hi, {{ formState.contactName }}<img style="margin-left: 12px;width: 32px;height: 32px;" src="../assets/images/toux.png" alt=""></span>
          </div>
        </a-popover>
      </div>
    </div>
    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" collapsible style="padding-top: 18px;" :class="{leftl: cbl, left2: !cbl}">
        <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline">
          <router-link to="/">
            <a-menu-item key="1">
              <!-- <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6df;</span> -->
              <div style="display: flex;align-items: center;">
                <img style="margin-right: 8px;width: 16px;height: 16px;" src="../assets/images/home.svg" alt="">
                <span style="vertical-align: middle;" :class="{'yc': zt}">首页</span>
              </div>
            </a-menu-item>
          </router-link>
          <router-link to="/dataReport">
            <a-menu-item key="2">
              <!-- <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6da;</span> -->
              <div style="display: flex;align-items: center;">
                <img style="margin-right: 8px;width: 16px;height: 16px;" src="../assets/images/reportforms.svg" alt="">
                <span style="vertical-align: middle;" :class="{'yc': zt}">数据报表</span>
              </div>
            </a-menu-item>
          </router-link>
          <router-link to="/dataReports">
            <a-menu-item key="3" v-if="show">
              <!-- <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6dc;</span> -->
              <div style="display: flex;align-items: center;">
                <img style="margin-right: 8px;width: 16px;height: 16px;" src="../assets/images/Realtime.svg" alt="">
                <span style="vertical-align: middle;" :class="{'yc': zt}">实时数据</span>
              </div>
              <!-- <div class="aaaaaaaaa" style="display: flex;align-items: center;">
                <svg t="1723446620741" class="icon color-change" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1839" width="24" height="24"><path d="M192 938.666667a42.666667 42.666667 0 0 1-42.666667-42.666667V472.746667l-37.319111 29.923555a42.666667 42.666667 0 1 1-53.361778-66.673778l426.666667-341.333333a42.666667 42.666667 0 0 1 53.361778 0l320 256 106.609778 85.333333a42.666667 42.666667 0 1 1-53.248 66.673778l-37.376-29.923555v423.253333a42.666667 42.666667 0 0 1-42.666667 42.666667H192zM512 182.613333l-277.333333 221.866667V853.333333h554.666666V404.48L512 182.613333z" p-id="1840"></path><path d="M661.333333 853.333333v-234.666666a42.666667 42.666667 0 0 0-42.666666-42.666667h-213.333334a42.666667 42.666667 0 0 0-42.666666 42.666667V853.333333h298.666666z m-213.333333-192h128V853.333333h-128v-192z" p-id="1841"></path></svg>
                <span style="vertical-align: middle;" :class="{'yc': zt}">实时数据</span>
              </div> -->
            </a-menu-item>
          </router-link>
          <router-link to="/comparison">
            <a-menu-item key="4" v-if="show">
              <!-- <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6db;</span> -->
              <div style="display: flex;align-items: center;">
                <img style="margin-right: 8px;width: 16px;height: 16px;" src="../assets/images/contrast.svg" alt="">
                <span style="vertical-align: middle;" :class="{'yc': zt}">数据对比</span>
              </div>
            </a-menu-item>
          </router-link>
        </a-menu>
        <div class="jtbx"><img @click="onimg" :class="{jt: true, jt2: !cbl}" src="../assets/images/jt.png" alt=""></div>
      </a-layout-sider>
      <a-layout>
        <a-layout-content>
          <a-breadcrumb>
          </a-breadcrumb>
          <div :style="{ height: '94vh', overflowY: 'auto'}">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <div class="bottom">SHICHUANG (HK) TECHNOLOGY LIMITED<span style="margin: 0 12px;">|</span>版本2.3.31<span style="margin: 0 12px;">|</span><a target="_Blank" href="https://beian.miit.gov.cn">琼ICP备2021009244号-9</a></div>
  </div>
</template>
<script setup>
import {
  PieChartOutlined,
  DesktopOutlined
} from '@ant-design/icons-vue';
import { onMounted, ref, inject } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { OutLogin } from "../sereive/index"
const route = useRoute()
let router = useRouter()
let collapsed = ref(false)
let selectedKeys = ref(['1'])
let i = ref(true)
var formState = ref({})
let show = ref(true)
let zt = ref(false)
let cbl = ref(true)
// let img = inject('img')
let img = require('../assets/images/logo.png')

function out() {
  OutLogin({username: route.query.username}).then((res) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("formState");
    router.push('/login')
  })
}

onMounted(async () => {
  formState.value = JSON.parse(sessionStorage.getItem("formState"))
  selectedKeys.value.length = 0
  selectedKeys.value = [`${route.meta.key}`]
  if (JSON.parse(sessionStorage.getItem("isShowRtData")) == 0) {
    show.value = false
  }
})
function click() {
  i.value = !i.value
}


function onimg() {
  zt.value = !zt.value
  cbl.value = !cbl.value
}
</script>
<style scoped lang="less">
a{
  color: rgba(34, 35, 38, 0.95) !important;
  text-decoration: none !important;
}

/* 选中菜单状态 */
/deep/ .ant-menu-item-selected {
  background-color: var(--global-primary-ccolor) !important;
  color: var(--global-primary-color) !important;
}
/* 设置未选中菜单项鼠标滑过样式 */
/deep/ .ant-menu-item-active {
  background-color: var(--global-primary-ccolor) !important;
  color: var(--global-primary-color) !important;
}

/* .aaaaaaaaa:hover .color-change{
  fill: red;
} */
/* .color-change{
  width: 16px;
  height: 16px;
  fill: blue;
} */


// .bgimg{
//   display: inline-block;
//   background-image: var(--global-primary-ccolor);
//   width: 100px;
//   // height: 200px;
// }

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.yc{
  display: none;
}
.site-layout .site-layout-background {
  background: #fff;
}
.bottom {
	margin-top: 6px;
	padding: 12px;
	border-color: rgba(0, 0, 0, 0.08);
  width: 100%;
  text-align: center;
  color: #3D3D3D;
	background: #FFFFFF;
}
.el-card {
  transition: opacity 3s;
  opacity: 0;
  position: absolute;
  min-width: 220px;
  right: 2px;
  top: 108%;
  z-index: 100;
  box-sizing: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #262626;
}
.el-card2{
  display: none;
}

.el-card3 {
  opacity: 1;
}

.el-card_header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.el-card_header div p {
  font-size: 16px;
  margin-bottom: 3px;
}

.bgBox {
  width: 50px;
  height: 50px;
  border: .5px solid #ccc;
  border-radius: 50%;
  margin-right: 18px;
}

.but {
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.but:hover {
  background-color: rgb(245, 247, 249);
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E5E6EB;
  width: 100%;
  height: 50px;
  background: #FFF;
  color: #28a5dc;
}

.left {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.img1 {
  width: 83px;
  height: 38px;
  margin-right: 20px;
}

.img2 {
  height: 22px;
  width: 202px;
}

.reight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.colLine {
  width: .5px;
  height: 16px;
  background: #79c3e4;
  margin: 5px;
}

.icon-bell {
  cursor: pointer;
  padding: 12px;
  position: relative;
}
.dl{
	padding: 6px 32px;
	text-align: center;
}
.dl:hover{
	background-color: #f5f7f9;
}

.username {
  padding: 15px 3px 15px 12px;
  cursor: pointer;
  color: #1D2129;
}

.ant-layout {
  height: 100% !important;
}
.jtbx{
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.jt{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.jt2{
  transform: rotate(-180deg);
}
/deep/ .leftl{
  position: relative !important;
  width: 200px !important;
  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
/deep/ .left2{
  position: relative !important;
  width: 80px !important;
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}
/deep/ .ant-layout-sider {
  background-color: #fff !important;
}

/deep/ .ant-layout-sider-trigger {
  position: absolute;
  background-color: rgb(230, 247, 255);
}

/deep/ .ant-layout-sider-trigger span svg {
  color: #bbb;
}
/deep/ .ant-menu-inline .ant-menu-item-selected::after{
  opacity: 0;
}
/deep/ .ant-layout-sider-trigger{
  display: none;
}
</style>